import React from 'react';
import { HashLink } from 'react-router-hash-link';

// names and links for each section
// last one is for seperate page with contact form
const NavLinks = () => {
    return (
        <>  
            <HashLink className="px-4 text-2xl font-semibold text-[#264653] hover:text-[#2f5768]" smooth to="/#start">
                Strona Główna
            </HashLink>
            <HashLink className="px-4 text-2xl font-semibold text-[#264653] hover:text-[#2f5768]" smooth to="/#about">
                O nas
            </HashLink>
            <HashLink className="px-4 text-2xl font-semibold text-[#264653] hover:text-[#2f5768]" smooth to="/#projects">
                Prace dyplomowe
            </HashLink>
            <HashLink className="px-4 text-2xl font-semibold text-[#264653] hover:text-[#2f5768]" to="/#students">
                Dydaktyka
            </HashLink>
        </>
    )
}

export default NavLinks;
