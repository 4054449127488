import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Projects_diploma from './pages/Projects_diploma';
import WBIO from './pages/WBIO';
import ADAC from './pages/ADAC';
import PCYB from './pages/PCYB';
import News from './pages/News';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("CyberBio");

  return (
    <>
      <Router>
        <ScrollToTop>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dyplomy" element={<Projects_diploma />} />
            <Route path="/wbio" element={<WBIO />} />
            <Route path="/adac" element={<ADAC />} />
            <Route path="/pcyb" element={<PCYB />} />
            <Route path="/news" element={<News />} />
            </Routes>
         </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
