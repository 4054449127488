import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar/NavBar';

// podstrona Aktualności

const News = () => {
    useEffect(() => {
        // Scroll the page to the top when the component mounts
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

    return (
        <>
            <div>
                <NavBar />
            </div>

            <div id='news' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                    <div className="my-4 py-4">

                        {/* tytuł podstrony */}
                        <h1 className="my-2 text-center text-3xl text-[#264653] uppercase font-extrabold">Aktualności</h1>

                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-[#264653] mb-8'></div>
                        </div>

                        <div className="px-4" data-aos="fade-down" data-aos-delay="600">

                            {/* div poniżej precyzuje grid o 3 kafelkach w rzędzie*/}
                            <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-5">

                                {/* 1 kafelek z inormacją o konsultacjach */}
                                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-[#264653] rounded-lg shadow-2xl p-3 min-h-max">
                                    <div className="m-2 text-justify text-sm">
                                        <h2 className="font-bold my-4 text-lg md:text-3xl text-center mb-4 h-12">Konsultacje</h2>
                                        <p className="md:text-2xl font-medium leading-5 h-auto md:h-48 text-center">
                                            Zapraszamy wszystkich zainteresowanych studentów na konsultacje. Prosimy uprzednio umówić się przez MS Teams lub e-mail.
                                        </p>
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Czwartki, godz. 12-14, pokój nr 409</h4>

                                    </div>
                                </div>

                                {/* 2 kafelek z inormacją*/}
                                <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-[#264653] rounded-lg shadow-2xl p-3">
                                    <div className="m-2 text-justify text-sm">
                                        <h4 className="font-bold my-4 text-lg md:text-3xl text-center mb-4 h-12">Ogłoszenie</h4>
                                        <p className="md:text-2xl font-medium leading-5 h-auto md:h-48 text-center">
                                            Tu jeszcze nic nie ma, ale jak tylko pojawi się nowa informacja to zostanie tutaj dodana.
                                        </p>

                                    </div>
                                </div>

                                {/* 3 kafelek z inormacją*/}
                                <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-[#264653] rounded-lg shadow-2xl p-3 min-h-max">
                                    <div className="m-2 text-justify text-sm">
                                        <h4 className="font-bold my-4 text-lg md:text-3xl text-center mb-4 h-12">Oferta stażu/praktyk</h4>
                                        <p className="md:text-2xl font-medium leading-5 h-auto md:h-48 text-center">
                                            Tu jeszcze nic nie ma, ale jak tylko pojawi się nowa oferta to zostanie tutaj dodana.
                                        </p>

                                    </div>
                                </div>

                                {/* tu można dodać więcej kafelków z informacją, powinno dodawać 3 kafelki w rzędzie*/}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default News;
