import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

// 'Prace dyplomowe' page 

const Projects_diploma = () => {
    useEffect(() => {
        // Scroll the page to the top when the component mounts
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

    return (
        <>
            <div>
                <NavBar />
            </div>

            <div id='wbio' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">


                    <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-full lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <div className="flex justify-center">
                            <h1 className="font-bold text-center text-center lg:text-left text-[#264653] uppercase text-3xl">Prace dyplomowe </h1>
                        </div>

                        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6  text-[#264653]">
                            <div className="text-center">
                                {/* dodać info o pracach dyplomowych; ogólnie o tematyce + wspomnienie o doktoracie? */}
                                <p> Tu znajdują się przykładowe tematy prac dyplomowych </p>
                                <p> </p>
                            </div>

                            <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left">
                                <div className="lg:w-1/2 flex flex-col lg:mx-4 items-center">

                                    <h3 className="text-2xl  text-[#264653] text-center font-bold">Przykładowe tematy lub tematyka prac inżynierskich:</h3>

                                    <div>
                                        <ul className="py-8 space-y-6 text-[#264653] text-justify">
                                            <li className="flex items-center space-x-2 px-8">
                                            "Wykorzystanie metod uczenia maszynowego do wykrywania technik phishingu z komunikacji mailowej"
                                            </li>
                                            <li className="flex items-center space-x-2 px-8">
                                                "Cyberbezpieczeństwo biologicznych baz danych"
                                            </li>
                                            <li className="flex items-center space-x-2 px-8">
                                                "Platforma internetowa do selekcji metod uczenia maszynowego w wykrywaniu i klasyfikacji zagrożeń w ruchu sieciowym"
                                            </li>
                                            <li className="flex items-center space-x-2 px-8">
                                                "Identyfikacja i klasyfikacja tekstów generowanych przez metody sztucznej inteligencji"
                                            </li>
                                            <li className="flex items-center space-x-2 px-8">
                                            
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="lg:w-1/2 flex flex-col lg:mx-4 items-center">

                                    <h3 className="text-2xl  text-[#264653] text-center font-bold">Przykładowe tematy lub tematyka prac magisterskich:</h3>
                                    <div>
                                        <ul className="py-8 space-y-6 text-[#264653] text-justify">
                                            <li className="flex items-center space-x-2 px-8">
                                            "Uwierzytelnienie z wykorzystaniem kryptografii w standardzie FIDO"
                                            </li>
                                            <li className="flex items-center space-x-2 px-8">
                                                "Zastosowanie uczenia maszynowego do detekcji anomalii w ruchu sieciowym"
                                            </li>
                                            <li className="flex items-center space-x-2 px-8">
                                                
                                            </li>
                                            <li className="flex items-center space-x-2 px-8">
                                                
                                            </li>
                                            <li className="flex items-center space-x-2 px-8">
                                                
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </>


    )
}

export default Projects_diploma;