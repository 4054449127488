import React from 'react';
import { Link } from 'react-router-dom';

// Sekcja o pracach dyplomowych

const Projects = () => {

    return (
        <div id="projects" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">

                    {/* titlte of the section */}
                    <h2 className="my-2 text-center text-3xl text-[#264653] uppercase font-bold">PRACE DYPLOMOWE</h2>

                    <div className='flex justify-center'>
                        {/* line under the title of this section*/}
                        <div className='w-24 border-b-4 border-[#264653]'></div>
                    </div>


                    {/* dodać  opis */}
                    <div className="text-center mt-4 text-[#264653] text-xl font-regular text-justify mx-16">
                        <p> Ta sekcja jest dedykowana studentom zainteresowanym napisaniem pracy dyplomowej w naszej grupie lub już będących jej częscią.
                            W tym miejscu mamy nadzieję zapewnić szczegółowy przegląd możliwości i wsparcia dla dyplomantów.
                            Tutaj znajdziesz informacje dotyczące przykładowych tematów pracy proponowanych przez nasz zespół, jak również
                            wskazówki związane z procesem pisania i przygotowywania pracy i prezentacji dyplomowej. </p>
                        <p>Zachęcamy do zapoznania się z naszymi wcześniejszymi osiągnięciami naukowymi i projektami, które realizujemy (w sekcji "O nas"),
                            aby uzyskać lepszy wgląd w nasze zainteresowania i możliwości współpracy.</p>
                        <p> Nasz zespół jest gotowy wspierać Cię w prowadzonych prze Ciebie badań, pomagając Ci osiągnąć Twoje cele
                            i rozwijać swoje umiejętności.</p>
                    </div>

                    {/* link do większej ilości informacji*/}
                    <div className="flex justify-center my-4">
                        <Link to="/dyplomy" className="text-white bg-[#264653] hover:bg-[#2f5768] inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            WIĘCEJ INFORMACJI
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>

                </div>

            </section>


            {/* Podsekcja z linkami */}
            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 items-center">

                            {/* obrazek download */}
                            <div className='text-[#264653] mb-4 items-center'>
                                <svg className='fill-current' width="72" height="72" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z">
                                    </path>
                                </svg>
                            </div>

                            {/* linki do kilku najważniejszych materiałów do ściągnięcia - więcej będzie na podstronie o pracach dyplomowych */}
                            <h3 className="text-3xl text-[#264653] font-bold">Materiały do ściągnięcia</h3>
                            <div>
                                <ul className="py-8 space-y-4 text-[#264653] text-xl font-medium ">
                                    <li className="flex items-center space-x-2 px-8">

                                    </li>
                                    <li className="flex items-center space-x-2 px-8">

                                    </li>
                                    <li className="flex items-center space-x-2 px-8">

                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-4 items-center">

                            {/* obrazek link */}
                            <div className='text-[#264653] mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div>

                            {/* linki do kilku najważniejszych linków - więcej będzie na podstronie o pracach dyplomowych */}
                            <h3 className="text-3xl text-[#264653] font-bold">Przydatne linki</h3>
                            <div>
                                <ul className="py-8 space-y-4 text-[#264653] text-xl font-medium text-justify">
                                    <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                        <a target="_blank" href="https://paulinaszczepanska.pl/2015/02/jak-zrobic-dobra-i-adna-prezentacje.html">"Jak zrobić dobrą (i ładną) prezentację"</a>
                                    </li>
                                    <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                        <a target="_blank"  href="https://klosinski.net/prezentacja-jak-zrobic-dobrze/">"Prezentacja - 10 zasad, dzięki którym zrobisz ją dobrze"</a>
                                    </li>
                                    <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                        <a target="_blank" href="http://bcpw.bg.pw.edu.pl/Content/1524/PoradnikPisaniaPracyDyplomowej.pdf">"Poradnik pisania pracy dyplomowej"</a>
                                    </li>
                                    <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                        <a target="_blank" href="https://www.elka.pw.edu.pl/Studenci/Prace-dyplomowe2">Informacje PW na temat pisania pracy dyplomowej</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Projects;