import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar/NavBar';

// podstrona poświęcona PCYB

const PCYB = () => {
    useEffect(() => {
        // Scroll the page to the top when the component mounts
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id='pcyb' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">


                    <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-full lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">

                        {/* tytuł podstrony */}
                        <div className="flex justify-center">
                            <h1 className="font-bold text-center lg:text-left text-[#264653] uppercase text-3xl">Podstawy Cyberbezpieczeństwa</h1>
                        </div>

                        <div className='flex justify-center'>
                            {/* line under the title of this section*/}
                            <div className='w-24 mt-8 border-b-4 border-[#264653]'></div>
                        </div>

                        {/*info o PCYB - dodać większy opis */}
                        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6  text-[#264653]">
                            <div className="text-center">
                                <p> Poniżej znajdują się przydatne materiały do zajęć.   </p>
                                <p> Dla zainteresowanych pogłębianiem wiedzy o
                                    przedmiocie zparaszamy do dodatkowej lektury. W tym celu zamieszczono kilka ciekawych artykułów, książek i postów. </p>
                            </div>

                            <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left">

                                {/* Linki do materiałów do ściągnięcia*/}
                                <div className="lg:w-1/2 flex flex-col lg:mx-4  items-center">
                                    <div className='text-[#264653] mb-4 items-center'>
                                        <svg className='fill-current' width="72" height="72" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z">
                                            </path>
                                        </svg>
                                    </div>
                                    <h3 className="text-3xl  text-[#264653] font-bold">Materiały do ściągnięcia:</h3>
                                    <div>
                                        <ul className="py-8 space-y-6 text-[#264653] text-justify">
                                            <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                                
                                            </li>
                                            <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                             
                                            </li>
                                            <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                               
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Co warto przeczytać + ew. linki do arktykułów + linki do postów np. na medium/towardsdatascience*/}
                                <div className="lg:w-1/2 flex flex-col lg:mx-4 items-center">
                                    <div className='text-[#264653] mb-4'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                                    </div>
                                    <h3 className="text-3xl  text-[#264653] font-bold">WARTO PRZECZYTAĆ:</h3>
                                    <div>
                                        <ul className="py-8 space-y-6 text-[#264653] text-justify">
                                            <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                               
                                            </li>
                                            <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                                
                                            </li>
                                            <li className="flex items-center space-x-2 px-8 hover:text-[#ec994b]">
                                                
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </>

    )
}

export default PCYB;
