import React from 'react';
import { Link } from 'react-router-dom';

// sekcja Dydaktyka

const Students = () => {
    return (
        <>
            <div className="my-4 py-4" id='students'>
                <h2 className="my-2 text-center text-3xl text-[#264653] uppercase font-bold">Dydaktyka</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-[#264653] mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-5">

                        {/* kafelek poświęcony WBIO */}
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-[#264653] hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-bold my-4 text-lg md:text-2xl text-center mb-4 h-12">Wstęp do Cyberbiobezpieczeństwa [WBIO]</h4>

                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Przedmiot stanowi wprowadzenie do obszaru CyberBioBezpieczeństwa. 
                                Omówione są podstawy zagadnień związanych z biologią molekularną, syntetyczną, biotechnologią 
                                i bioinformatyką – punktu wyjścia do zrozumienia specyfiki cyberbiobezpieczeństwa. 
                                Tematyka przedmiotu obejmuje zagadnienia związane przede wszystkim z wykorzystaniem tzw. metod 
                                bio-inspirowanych w cyberbezpieczeństwie oraz ich zastosowaniem w odniesieniu do pojedynczego użytkownika, 
                                jak również w aplikacjach wielkoskalowych. 
                                Omówiona jest też specyfika, potencjalne zagrożenia, metodyki ich wykrywania, monitorowania oraz zabezpieczeń.
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/wbio" className="text-white bg-[#264653] hover:bg-[#2f5768] inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                        Więcej o WBIO
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        
                        {/* kafelek poświęcony ADAC */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-[#264653] hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-bold my-4 text-lg md:text-2xl text-center mb-4 h-12">Analiza Danych w Cyberbezpieczeństwie [ADAC]</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                W ramach zajęć studentci pozyskują wiedzę i umiejętności z zakresu analizy danych: stawiania hipotez, 
                                poszukiwania zależności, budowy modeli służących testowaniu hipotez i ich weryfikacji 
                                w dużych zbiorach danych w kontekście cyberbezpieczeństwa.
                                </p>

                                <div className="flex justify-center my-4">
                                    <Link to="/adac" className="text-white bg-[#264653] hover:bg-[#2f5768] inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                        Więcej o ADAC
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* kafelek poświęcony PCYB*/}
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-[#264653] hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-bold my-4 text-lg md:text-2xl text-center mb-4 h-12">Podstawy Cyberbezpieczeństwa [PCYB]</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Głównym celem przedmiotu jest zaznajomienie studentów z podstawami cyberbezpieczeństwa. 
                                W ramach przedmiotu omówione zostaną fundamentalne zagadnienia dotyczące bezpieczeństwa sieci, 
                                systemów i użytkowników wzajemnie na siebie oddziałowujących w świecie cyfrowym. W ramach wprowadzonego 
                                kontekstu bezpieczeństwa cyberprzestrzeni przestawione zostaną trzy istotne obszary: analiza, 
                                detekcja złośliwego oprogramowania (malware), testy penetracyjne oraz kryminalistyka cyfrowa (digital forensics).
                                </p>

                                <div className="flex justify-center my-4">
                                    <Link to="/pcyb" className="text-white bg-[#264653] hover:bg-[#2f5768] inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                        Więcej o PCYB
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Students;