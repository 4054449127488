import React from 'react';
import Footer from '../components/Footer';
import Start from '../components/Start';
import About from '../components/About';
import Students from '../components/Students';
import Projects from '../components/Projects';


const Home = () => {
    return (
        <>
            <Start />
            <About />
            <Projects />
            <Students />
            <Footer />
        </>

    )
}

export default Home;

