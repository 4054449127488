import React from 'react';
import img from '../images/img/icons/logo.png';

// Sekcja "O nas"

const About = () => {
    return (
        <>
            <div className="m-auto max-w-7xl p-2 md:p-12 h-5/6" id='about' >

                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                    {/* obrazek przy sekcji "O nas"*/}
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center px-8 mr-16">
                        <img alt="card img" className="rounded-t float-right w-80 h-80" src={img} />
                    </div>

                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/1 px-8" data-aos="zoom-in" data-aos-delay="500">

                        {/* tytuł sekcji */}
                        <h2 className="text-4xl my-2 py-4 uppercase text-[#264653] text-center font-extrabold"> O nas</h2>

                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-[#264653] mb-8'></div>
                        </div>

                        {/* short description of our scientific interests */}
                        <div>
                            <p className='my-3 text-xl text-[#264653] font-regular text-justify'>Jesteśmy małym i młodym zespołem badawczym skoncentrowanym głównie 
                            na wykorzystaniu technik uczenia maszynowego w projektowaniu i optymalizacji nowych leków oraz w cyberbezpieczeństwie. 
                            Nasz zespół tworzy grupa specjalistów z różnych obszarów nauki, którzy
                            współpracują nad innowacyjnymi rozwiązaniami. Nasze prace obejmują rozwój modeli predykcyjnych dla nowych związków 
                            chemicznych oraz analizę danych w celu identyfikacji potencjalnych zagrożeń cybernetycznych i opracowania skutecznych strategii obronnych. </p>
                            <p> </p>
                        </div>

                        {/* tytuł podsekcji i lista naszych projektów */}
                        <h3 className="text-2xl  text-[#264653] font-bold text-center"> Nasze projekty </h3>
                        <div>
                            <p className='my-3 text-xl text-[#264653] font-regular text-justify'>Poniżej przedstawiamy listę projektów, które były realizowane przez nas lub przy naszym udziale.</p>
                            <ul className=' '>
                                <li className='my-3 text-[#264653] font-regular text-justify'>
                                    "Centrum monitorowania instalacji przemysłowych w podziemnych zakładach górniczych i wykrywania cyberzagrożeń" - CyberMine - projekt NCBiR
                                </li>
                                <li className='my-3 text-[#264653] font-regular text-justify'>
                                    “System wspomagania detekcji i obrazowania ataków APT” - DAPT - projekt CyberSecIdent IV
                                </li>
                                <li className='my-3 text-[#264653] font-regular text-justify'>
                                    “Opracowanie prekursorów nowego typu leku umożliwiającego leczenie infekcji wirusami z rodziny Coronaviridae, w tym SARS-CoV-2” - projekt IDUB against COVID-19
                                </li>
                                <li className='my-3 text-[#264653] font-regular text-justify'>
                                    “Zintegrowany system informatyczny wykorzystujący metody sztucznej inteligencji do automatycznej optymalizacji kandydatów na nowe leki" - LEADOMISE - projekt NCBiR, LIDER IX
                                </li>
                            </ul>
                        </div>

                        {/* tytuł podsekcji i lista naszych publikacji */}
                        {/*
                        <h3 className="text-2xl  text-[#264653] font-bold text-center"> Nasze publikacje </h3>
                        <div>
                            <ul className=''>
                                <li className='my-3 text-[#264653] font-regular text-justify'>
                                    Zarnecka, J.; Kaminska K. FRAGMENTISE - the user-friendly, cross-platform tool to create and analyze comprehensive small-molecule fragment databases. J Comput Chem. 2023, 44(26), 2096. <a href=" https://doi.org/10.1002/jcc.27183" className='font-semibold'> https://doi.org/10.1002/jcc.27183 </a>
                                </li>
                                <li className='my-3 text-[#264653] font-regular text-justify'>
                                    Publikacja 2
                                </li>
                                <li className='my-3 text-[#264653] font-regular text-justify'>
                                    Publikacja 3
                                </li>
                            </ul>
                        </div>
                         */}

                        {/* link to mail message to Kasia (pw email) */}
                        <a href="mailto:katarzyna.kaminska@pw.edu.pl" className="text-white bg-[#264653] hover:bg-[#2f5768] inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Napisz maila
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </a>

                    </div>
                </div>
            </div>
        </>
    )
}

export default About;