import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import NavBar from './Navbar/NavBar';
import graphImg from '../images/img/grafiki.png';

// sekcja START - wstęp do strony

const Start = () => {
    useEffect(() => {
        // Scroll the page to the top when the component mounts
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);
    return (
        <>
            <div className="start" id='start'>
                <div>
                    <NavBar />
                </div>

                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">

                    <div id='start' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">

                            {/* Title of the Start section*/}
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-[#264653] text-center">
                                CYBERBIO
                            </h1>

                            {/* kilka słów o stronie */}
                            <div className="text-xl font-regular tracking-tight mb-5 text-[#264653] text-justify">

                                <p className='mb-2'>
                                Witamy na naszej platformie, stworzonej z myślą przede wszystkim o studentach kierunku Cyberbezpieczeństwo, którzy poszukują wsparcia 
                                oraz inspiracji na każdym etapie swojej naukowej podróży! </p>
                                <p className='mb-2'>Tutaj znajdują się nie tylko informacje o naszych zajęciach i pisaniu pracy dyplomowej, ale również o możliwościach 
                                podjęcia stażu w naszym zespole. </p>
                                <p className='mb-2'>W sekcji <b>Aktualności</b> znajdują się najważniejsze ogłoszenia związane z prowadzonymi przez nas zajęciami, jak i 
                                godziny dostępnych dla naszych studentów konsultacji. </p>
                                <p className='mb-2'> Sekcja <b>Dydaktyka</b> oferuje szczegółowe opisy prowadzonych zajęć: Analiza Danych w Cyberbezpieczeństwie, Wstęp do Cyberbiobezpieczeństwa i 
                                    Podstawy Cyberbezpieczeństwa. A dla tych z Was, którzy pragną podjąć wyzwanie napisania pracy dyplomowej w naszej 
                                grupie badawczej, przygotowaliśmy specjalną sekcję Prace Dyplomowe, z opisem naszych zainteresowań i możliwości współpracy.</p> 
                                <p className='mb-2 uppercase font-semibold'>Dołącz do naszego zespołu i odkryj, w jaki sposób możemy Ci pomóc rozwijać naukowe pasje i umiejętności!
                                </p>

                            </div>

                            {/* Links to  other sections */}
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">

                                <Link to="/news" className="text-white bg-[#264653] hover:bg-[#2f5768] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Aktualności
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                                <HashLink to="#projects" className="text-white bg-[#264653] hover:bg-[#2f5768] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Prace dyplomowe
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </HashLink>
                                <HashLink to="#students" className="text-white bg-[#264653] hover:bg-[#2f5768] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Dydaktyka
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </HashLink>
                            </div>
                        </div>

                        {/* image for Start section */}
                        <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={graphImg} />
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Start;